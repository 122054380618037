import React from 'react';
import PropTypes from 'prop-types';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import { graphql, navigate } from 'gatsby';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import { Odin } from '../src/components/Odinv2/Odin';
import { Freya } from '../src/components/Freya/Freya';
import Ymir from '../src/components/Ymir/Ymir';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import Hod from '../src/components/Hod/Hod';
import { YmirHeader } from '../src/components/Titles/YmirHeader/YmirHeaderv2';
import Astrid from '../src/components/Astrid/Astrid';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { generatedBreadCrumb } from '../src/helpers/breadCrumbs';
import SEO from '../src/helpers/seo';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import tt from '../src/helpers/translation';
import Gerd from '../src/components/Gerd/Gerd';

import './distributiva_promociones.scss';

class PromotionHalloween extends React.Component {
  state = {};

  componentDidMount() {
    if (this.props.location.hash) {
      navigate(this.props.location.pathname + this.props.location.hash);
    }
  }

  handleMap = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const titleH3Data = {
      // title: this.props.data.allDistHalloweenPromosTitleBlock.edges[0].node.title.toUpperCase(),
      title: tt('PRÓXIMAMENTE', this.props.pageContext.locale),
    };

    const titleH3Data2 = {
      title: tt('PROMOCIONES VIGENTES', this.props.pageContext.locale),
    };

    const heimdallData = {
      name: this.props.data.allDistHalloweenPromosTitleBlock.edges[0].node.title,
      subtitle: this.props.data.allDistHalloweenPromosTitleBlock.edges[0].node.subTitle,
      image: {
        url: this.props.data.allDistHalloweenPromosTitleImageBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
      imageMobile: {
        url: this.props.data.allDistHalloweenPromosTitleMobileImageBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
      map: 'true',
    };

    const odinData = {
      image:
        this.props.data.allDistHalloweenPromosEventBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      alt: this.props.data.allDistHalloweenPromosEventBlock.edges[0].node.alt,
      title: this.props.data.allDistHalloweenPromosEventBlock.edges[0].node.event_name,
      description: this.props.data.allDistHalloweenPromosEventBlock.edges[0].node.description,
      buttons: {
        cta1: this.props.data.allDistHalloweenPromosEventBlock.edges[0].node.buttons.cta1,
        cta2: buildFunnelUrl(this.props.pageContext.locale, 'hotels'),
        ctaText1: this.props.data.allDistHalloweenPromosEventBlock.edges[0].node.buttons.ctaText1,
        ctaText2: tt('RESERVAR', this.props.pageContext.locale),
        size: 'small',
        color1: 'white',
        color2: 'orange',
      },
    };

    // const freyaData = {
    //   cards: this.props.data.allDistHalloweenPromosPromocionesSubBlock.edges.map((item) => ({
    //     image: item.node.localImage.childImageSharp.fluid,
    //     altImage: item.node.image_alt,
    //     title: item.node.title,
    //     imgsize: item.node.imgsize,
    //     twoButton: true,
    //     buttons: {
    //       cta: item.node.buttons.cta,
    //       size: 'small',
    //       color: item.node.buttons.color,
    //       ctaText: item.node.buttons.ctaText,
    //       cta2: item.node.buttons.cta2,
    //       size2: 'small',
    //       color2: 'orange',
    //       ctaText2: item.node.buttons.ctaText2,
    //     },
    //   })),
    // };

    const ymirData = {
      title: this.props.data.allDistHalloweenPromosInformativeBlock.edges[0].node.title,
      subtitle: this.props.data.allDistHalloweenPromosInformativeBlock.edges[0].node.content,
      cards: [
        {
          title: tt('GRUPO ESCOLAR (4-17 AÑOS)', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('grupos/grupos-escolares', this.props.pageContext.locale),
          icon: 'grupos1',
        },
        {
          title: tt('GRUPO VACACIONAL', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/grupos/grupos-vacacionales', this.props.pageContext.locale),
          icon: 'grupos2',
        },
        {
          title: tt('FAMILIAS NUMEROSAS', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/entradas/familia-numerosa', this.props.pageContext.locale),
          icon: 'grupos3',
        },
      ],
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allDistHalloweenPromosSeoData.edges[0].node._0.title}
          description={this.props.data.allDistHalloweenPromosSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} handleMap={this.handleMap} />
          <div className="promotions-content">
            <NeoAesir
              locale={this.props.pageContext.locale}
              pageName={this.props.pageContext.pageName}
              title={this.props.data.allDistHalloweenPromosBreadCrumbBlock.edges[0].node.name}
            />

            <Hod
              data={this.props.data.allDistHalloweenRichData.edges[0].node.rich_markup}
              url={this.props.pageContext.url}
              img={
                this.props.data.allDistHalloweenPromosTitleImageBlock.edges[0].node.localImage
                  .childImageSharp.fluid
              }
              hardcoded={
                this.props.data.allDistHalloweenSeoHardcoded.edges.length &&
                this.props.data.allDistHalloweenSeoHardcoded.edges[0].node.richmark_text
              }
            />
            <Astrid
              pageContext={this.props.pageContext}
              handleMap={this.handleMap}
              modal={this.state.showModal}
            />
            <H3 data={titleH3Data} />
            {/* <Odin data={odinData} locale={this.props.pageContext.locale} /> */}
            <div className="spacer_halloween" />
            {/* <H3 data={titleH3Data} /> */}
            {/* <Freya data={freyaData} /> */}
            <a id="group" />
            <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
            <div className="promotions-content-promo">
              <YmirHeader
                title={ymirData.title}
                subtitle={ymirData.subtitle}
                subtitle2={ymirData.subtitle2}
              />
              <Ymir data={ymirData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PromotionHalloween;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query promotions_halloween($locale: String!) {
    allDistHalloweenPromosSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allDistHalloweenPromosTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subTitle
        }
      }
    }
    allDistHalloweenPromosTitleImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allDistHalloweenPromosTitleMobileImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allDistHalloweenPromosPromocionesSubBlock(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          id
          title
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          stars
          buttons {
            cta
            size
            color
            ctaText
            ctaText2
            cta2
          }
          description
        }
      }
    }

    allDistHalloweenPromosEventBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          description
          event_name
          title
          buttons {
            cta1
            ctaText1
          }
        }
      }
    }

    allDistHalloweenPromosInformativeBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          content
        }
      }
    }
    allDistHalloweenPromosBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allDistHalloweenSeoHardcoded(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          richmark_text
        }
      }
    }
    allDistHalloweenRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;
